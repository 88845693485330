import Helmet from "react-helmet"
import React from "react"
import HomepageLayout from "src/layouts/homepage"
import Trbm_language from "src/components/trbm_language"
import CtaDisclosureFf from "src/components/ctadisclosureff"
import {isIeEdge,isChrome,isFirefox} from "@tightrope/lpscripts/browserdetect"


const bmData = import("./data/data.json");

export default function trbmDe() {

  return(
    <HomepageLayout>
    <Helmet>
     <style type="text/css">{`
    body {
      background-image: url(/img/laptop-dark.jpg);
      background-repeat: no-repeat;
      background-size: cover;
      background-attachment: fixed;
      background-position-x: center;
    }

    .trbm-module--trbm h1 {
      font-weight: bold;
    }

    .trbm-module--trbm h2 {
      color: #333;
    }

    .trbm-module--trbm .trbm-module--bullets li {
      font-weight: lighter;
    }

    .trbm-module--trbm .trbm-module--bullets {
        list-style: disc;
        width: 66%;
    }

    .footerlinks-module--br--2T_Dp,
     .footerlinks-module--br--2T_Dp a{
      color: rgba(255,255,255,0.6);
    }

    .trbm_cta {
        padding: 15px 100px;
        border: none;
        font-size: 35px;
        color: #fff;
        font-weight: bold;
        background: #43ba19;
        border-radius: 10px;
        cursor: pointer;
        transition: .2s ease;
    }

    .footerlinks-module--br, .footerlinks-module--br a {
      color: #f1f1f1;
    }

    .trbm-module--trbm .trbm-module--steps .trbm-module--step1 {
      left: 15px;
    }

    .trbm-module--trbm .trbm-module--steps .trbm-module--step2 {
      left: 142px;
    }

    #arrow-direction-prompt-complete {
      padding-top: 28px !important;
    }

    .arrow-direction {
    text-align: center;
    }

    .arrow-ico {
    top: 45px !important;
    left: 25px !important;
    }

    `}
    </style>

    <title>Free PDF Editor - pdftab.com</title></Helmet>
    <section>
      <Trbm_language data={bmData} language='fr'>
        <CtaDisclosureFf data={bmData} language='fr'></CtaDisclosureFf>

      </Trbm_language>
      </section>
    </HomepageLayout>
  )
}
